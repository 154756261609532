
.sidebar-collapse header {
    width: calc(100% - 50px);
    margin-left: 50px;
}

.sidebar-collapse .main-sidebar .top-menu > .nav-text,
.sidebar-collapse .brand-text .logo-text{
    opacity: 0;
    visibility: hidden;
}

.customer-logo {
  max-width: 100px;
}
.customer-logo img {
 width: 100%;
}

.logo_under-border {
 max-width: 30px;
 height: 10px;
}
.logo_under-border img {
 width: 100%;
}


.sidebar-collapse .main-sidebar .sub-menu .nav-text{
  /* font-size: 12px; */
  display: flex;
  justify-content: center;
}

.sub-menu .sub-link:hover {
  background-color: #eee;
}

.sidebar-collapse .main-sidebar {
    width: 50px;
}

.sidebar-collapse .main-sidebar .brand-text .logo{
  margin-left: 0;
  padding: .25rem;
}

.sidebar-collapse .top-menu:after {
  opacity: 0;
  visibility: hidden;
}

.sidebar-collapse .menu .sub-menu .sub-link .nav-text{
  /* color: #d7d4d2; */
  margin-left: 0;
}


  
header {
    margin-left: 250px;
    width: calc(100% - 250px);
    z-index: 50;
    transition: all .4s ease;
    background-color: #fff;
    border-bottom: 1px solid #DEE2E6;
    height: 60px;
}



  h1.logo {
    width: 100px;
    margin: 0rem 1.5rem 0rem 1.5rem;
  }



  .user-menu .nav-item-box > .nav-item-bg {
    background: #F6F7F8;
    border-radius: 8px;
    width: 36px;
    height: 36px;
    position: relative;
    display: block;
    text-decoration: none;
}

.user-menu .nav-item-box i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    font-size: 22px;
}

.user-menu .nav-item-box:hover i {
 color: #FF9F43;
}



.main-sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 250px;
  background: #fff;
  transition: all 0.4s ease;
  z-index: 190;  
}

.main-sidebar .menu-bar{
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.menu-bar::-webkit-scrollbar{
  display: none;
}



.main-sidebar .icon {
  color: #fff;
  font-size: 14px;
  min-width: 40px;
  transition: all 0.3s ease;
  text-align: center;
}

.main-sidebar li {
  margin-bottom: .1rem;
}

.main-sidebar li span.nav-text{
  list-style: none;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: .4rem 0;
  white-space: nowrap;
  opacity: 1;
}


 /* Logo Box */
#log-box {
  height: 60px;
  border-bottom: 1px solid #DEE2E6;
}

/* Top Menu */



/* Sub Menu */
/* 아코디언 메뉴가 접히는 것처럼 보여지는 CSS */
.menu .sub-menu {
  position:static;
  opacity: 1;
  visibility: visible;
  transform: none;
  left: 0;
  bottom: 0;
  overflow: hidden;
  height: 100%;
  max-height: 0;
  overflow: hidden;
}
/* 아코디언 메뉴 보이기 */
.menu .sub-menu.active {
  max-height: 300px;
}

.menu .sub-menu .sub-link a{
  display: block;
  border-bottom: 1px solid #f1f1f1;
  text-decoration: none;
  transition: all .1s ease-in;
  text-decoration: none;
}

.menu .sub-menu .sub-link .nav-text{
  /* color: #d7d4d2; */
  /* margin-left: 10px; */
}


.menu .sub-menu .sub-link .nav-text .caret-icon{
  /* color: #d7d4d2; */
  display: inline-block;
  min-width: 40px;
}

.nav-link {
  padding: 10px 0;
  /* color: #fff; */
}

/* 활성화, hover 메뉴 */
.top-menu.menu_active {
   background: rgba(254, 159, 67, 0.08);
   border-radius: 5px;
   color: #FE9F43;
  font-weight: 600;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.top-menu.menu_active i{
   color: #FE9F43;
}


.menu .sub-menu .sub-link a.now,
.menu .sub-menu .sub-link a:hover {
  box-shadow: none;
  font-weight: bold;
  background-color: #eeeeee;
}
.menu .sub-menu .sub-link a.now .nav-text,
.menu .sub-menu .sub-link a:hover .nav-text{
  color: #373330;
}

.menu .nav-link:hover span,
.menu .nav-link:hover .icon i {
  color: #FF9F43;
  /* transition: all 0.2s ease; */
}


.top-menu {
  border-bottom: 1px solid #DEE2E6;
}

/* non-active 삼각형 화살표 */
.top-menu:not(.no_submenu):after {
  content: '';
  display: block; 
  position: absolute;
  top: 45%;
  right: 8%;
  width:  7px;
  height: 7px;
  border: 3px solid #3d3d3d;
  border-bottom: transparent;
  border-right: transparent;
  transform:  rotate(-45deg);
  border-radius: 2px;
  transition:  all .3s ease-in-out;
}

/* active 삼각형 화살표 */
.top-menu.active:after {
  transform:  rotate(-135deg);
}


.user-icon-box {
  width: 11.5rem;
  height: 100%;
}

.user-icon-box .user-info-wrap {
  height: 60px;
}

.user-icon-box .mycontrol-box{
  position: absolute;
  background-color: #f5f5f5;
  width: inherit;
  left:0;
  top: 59px;
  text-align: left;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
}



.user-icon-box .mycontrol-box li p, 
.user-icon-box .mycontrol-box li a {
  padding: .5rem 0 .5rem 2rem;
} 

.user-icon-box .mycontrol-box li:hover {
  background-color:  #EAECEF;
}

.user-icon-box .mycontrol-box li:hover a{
  /* color: inherit; */
  text-decoration: none;
}


.user-icon-box .mycontrol-box li:nth-child(1) {
  border-bottom: 1px solid #e0e0e0;
}

.user-info {
  color: #373330;
  margin-left: 8px;
  font-size: 10px;

}


.sidebar-toggle-icon {
  position: absolute;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  top: 10px;
  right: -20px;
  background-color: #FE9F43;
}





/* 전체 contants container */
#contents-container{
  margin-left: 250px;
  padding-top: 60px;
  transition: all .4s ease;
  display: flex;
  flex-direction: column;
	justify-content: space-between;
  height: 100vh;
}

.sidebar-collapse #contents-container{
  margin-left: 50px;
}

.sidebar-closed .main-sidebar,
.sidebar-collapse .main-sidebar{
  border : 1px solid #DEE2E6
  /* box-shadow: 0 2px 9px -2px rgba(50,71,92 , 0.06),0 4px 9px 1px rgba(50,71,92, 0.02),0 2px 6px 4px rgba(50,71,92 , 0.02); */
}

.top-menu.nav-link {
  padding-left: 10px;
}

.sidebar-collapse .top-menu.nav-link {
  padding-left: 5px;
}

/* 프로필 이미지 */



@media (max-width: 990px) {
    #main-sidebar_bg {
        position: fixed;
        content: '';
        background-color: rgba(0, 0, 0, 60%);
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 180;
      }
    
    .sidebar-open header,
    .sidebar-closed header {
          margin-left: 0;
          width: 100%;
    }
      
    .sidebar-collapse .main-sidebar {
      width: 250px;
    }
  
   .sidebar-closed .main-sidebar {
      margin-left: -250px;
   }

   .sidebar-collapse .main-sidebar .brand-text .logo {
    margin-left: 10px;
}
   
    .sidebar-collapse .top-menu:after,
    .sidebar-open .main-sidebar .top-menu > .nav-text,
    .sidebar-open .brand-text .logo-text {
        opacity: 1;
      visibility: visible;
    }

     .sidebar-open .main-sidebar {
      box-shadow: 0 14px 28px rgba(0,0,0,.25),0 10px 10px rgba(0,0,0,.22);
     }

     .sidebar-closed .main-sidebar {
        box-shadow: none;
      }


    
     #contents-container {
      margin-left: 15px;
      margin-right: 15px;
    } 
}



