@charset "UTF-8";
@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@300;400;500;700&display=swap);
@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,600;0,700;1,700&display=swap');
@import url(./reset.css);
@import url(./sidebar.css);
@import url(./button.css);

/*----------- HTML TAG CUSTOM CSS -----------*/

/*----------- HTML TAG CUSTOM CSS -----------*/
html {
  /* height: 100%; */
  
  background-color: #FAFBFE;
}

body {
  background-color: #FAFBFE;
}

#root {
  /* background-color: #f3f3f9; */
  font-family: "Noto Sans KR", sans-serif;
  color: #111;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



/* 
 thead {
  background-color: #f3f6f9;
} */


.b-red {
  border: 1px solid red;
}

.b-blue {
  border: 1px solid blue;
}
/* 
input {
  border: none;
  border: 1px solid #ccc;
  width: 100%;
  padding: 0.3rem 0.5rem 0.3rem 0.7rem;
  border-radius: 3px;
}


input[type="checkbox"],
input[type="radio"] {
  outline: 0px solid #111;;
}
input[type="checkbox"]:checked,
input[type="radio"]:checked {
  accent-color: #74992e;
  color: white;
} */


input:focus,
textarea:focus {
  outline: 1px solid #FF9F43;
}

input,
select {
  border: 1px solid rgba(145, 158, 171, 0.32);
    height: 40px;
    width: 100%;
    font-size: 14px;
    font-weight: 500;
    color: #637381;
    padding: 10px 15px;
    border-radius: 5px;
}
input.pd-light {
  font-size: 14px;
    height: 30px;
    padding: 5px 5px;
}

input:disabled {
  background: #e9e9e9;
  border: none;
}

/* input, */
input.form-control {
  border-radius: .2rem;
  padding: 0.25rem 0.75rem;
}


input.form-control::placeholder {
  color: #bababa;
  font-size: 12px;
}

select.form-select {
  padding: 0.18rem 0.75rem;
  border-radius: .2rem;
}

/*----------- font 관련 CSS  -----------*/
.eng-600 {
  font-family: 'Crimson Text', serif;
  font-weight: 600;
}

.eng-700 {
  font-family: 'Crimson Text', serif;
  font-weight: 700;
}
.color-white {
  color: #fff;
}
.color-bright {
  color: #cacaca;
}
.color-light {
  color: #999;
}
.color-mid {
  color: #67748E;
}
.color-dark {
  color: #121935;
}

.color-orange {
  color: #FF9F43;
}

.color-orange-hover:hover {
  color: #87bd2b;
}
.color-hover:hover {
  color: #5c9bde;
}
.color-red {
  color: #ff0000;
}
.color-blue {
  color: #152983;
}

.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-700 {
  font-weight: 700;
}
.font-weight-800 {
  font-weight: 800;
}
.font-weight-900 {
  font-weight: 900;
}
.font-bold {
  font-weight: bold;
}
.txt-underline {
  text-decoration: underline;
}

/* ------- border 관련 CSS -------- */
.border-dark {
  border: 1px solid #333;
}

.border-white {
  border: 1px solid #fff;
}

.border-brand {
  border: 1px solid #87bd2b;
}

.border-gray-bottom {
  border-bottom: 1px solid #ddd;
}

.border-bright {
  border: 1px solid #cacaca;
}

/*----------- display -----------*/
/*----------- margin & padding -----------*/
.contents_top_mg {
  margin-top: 5rem;
}

.contents_top_pd {
  padding-top: 5rem;
}

.contents_bottom_mg {
  margin-bottom: 8rem;
}

.contents_bottom_pd {
  padding-bottom: 8rem;
}

.mg-0 {
  margin: 0 !important;
}
.mg-5 {
  margin: 5px !important;
}
.mg-10 {
  margin: 10px !important;
}
.mg-15 {
  margin: 15px !important;
}
.mg-20 {
  margin: 20px !important;
}
.mg-25 {
  margin: 25px !important;
}
.mg-30 {
  margin: 30px !important;
}
.mg-35 {
  margin: 35px !important;
}
.mg-40 {
  margin: 40px !important;
}
.mg-45 {
  margin: 45px !important;
}
.mg-50 {
  margin: 50px !important;
}

.mg-t0 {
  margin-top: 0 !important;
}
.mg-t3 {
  margin-top: 3px !important;
}
.mg-t5 {
  margin-top: 5px !important;
}
.mg-t10 {
  margin-top: 10px !important;
}
.mg-t15 {
  margin-top: 15px !important;
}
.mg-t20 {
  margin-top: 20px !important;
}
.mg-t25 {
  margin-top: 25px !important;
}
.mg-t30 {
  margin-top: 30px !important;
}
.mg-t35 {
  margin-top: 35px !important;
}
.mg-t40 {
  margin-top: 40px !important;
}
.mg-t45 {
  margin-top: 45px !important;
}
.mg-t50 {
  margin-top: 50px !important;
}
.mg-t60 {
  margin-top: 60px !important;
}
.mg-t70 {
  margin-top: 70px !important;
}
.mg-t80 {
  margin-top: 80px !important;
}
.mg-t90 {
  margin-top: 90px !important;
}
.mg-t100 {
  margin-top: 100px !important;
}

.mg-l0 {
  margin-left: 0 !important;
}
.mg-l5 {
  margin-left: 5px !important;
}
.mg-l10 {
  margin-left: 10px !important;
}
.mg-l15 {
  margin-left: 15px !important;
}
.mg-l20 {
  margin-left: 20px !important;
}
.mg-l25 {
  margin-left: 25px !important;
}
.mg-l30 {
  margin-left: 30px !important;
}
.mg-l35 {
  margin-left: 35px !important;
}
.mg-l40 {
  margin-left: 40px !important;
}
.mg-l45 {
  margin-left: 45px !important;
}
.mg-l50 {
  margin-left: 50px !important;
}

.mg-r0 {
  margin-right: 0 !important;
}
.mg-r3 {
  margin-right: 3px !important;
}
.mg-r5 {
  margin-right: 5px !important;
}
.mg-r10 {
  margin-right: 10px !important;
}
.mg-r15 {
  margin-right: 15px !important;
}
.mg-r20 {
  margin-right: 20px !important;
}
.mg-r25 {
  margin-right: 25px !important;
}
.mg-r30 {
  margin-right: 30px !important;
}
.mg-r35 {
  margin-right: 35px !important;
}
.mg-r40 {
  margin-right: 40px !important;
}
.mg-r45 {
  margin-right: 45px !important;
}
.mg-r50 {
  margin-right: 50px !important;
}

.mg-b0 {
  margin-bottom: 0 !important;
}
.mg-b3 {
  margin-bottom: 3px !important;
}
.mg-b5 {
  margin-bottom: 5px !important;
}
.mg-b10 {
  margin-bottom: 10px !important;
}
.mg-b15 {
  margin-bottom: 15px !important;
}
.mg-b20 {
  margin-bottom: 20px !important;
}
.mg-b25 {
  margin-bottom: 25px !important;
}
.mg-b30 {
  margin-bottom: 30px !important;
}
.mg-b35 {
  margin-bottom: 35px !important;
}
.mg-b40 {
  margin-bottom: 40px !important;
}
.mg-b45 {
  margin-bottom: 45px !important;
}
.mg-b50 {
  margin-bottom: 50px !important;
}
.mg-b60 {
  margin-bottom: 60px !important;
}
.mg-b70 {
  margin-bottom: 70px !important;
}

.pd-l1rem {
  padding-left: 1rem !important;
}
.pd-r1rem {
  padding-right: 1rem !important;
}
.pd-t1rem {
  padding-top: 1rem !important;
}
.pd-b1rem {
  padding-bottom: 1rem !important;
}

.pd-0 {
  padding: 0 !important;
}
.pd-5 {
  padding: 5px !important;
}
.pd-10 {
  padding: 10px !important;
}
.pd-15 {
  padding: 15px !important;
}
.pd-20 {
  padding: 20px !important;
}
.pd-25 {
  padding: 25px !important;
}
.pd-30 {
  padding: 30px !important;
}
.pd-35 {
  padding: 35px !important;
}
.pd-40 {
  padding: 40px !important;
}
.pd-45 {
  padding: 45px !important;
}
.pd-50 {
  padding: 50px !important;
}

.pd-t0 {
  padding-top: 0 !important;
}
.pd-t5 {
  padding-top: 5px !important;
}
.pd-t10 {
  padding-top: 10px !important;
}
.pd-t15 {
  padding-top: 15px !important;
}
.pd-t20 {
  padding-top: 20px !important;
}
.pd-t25 {
  padding-top: 25px !important;
}
.pd-t30 {
  padding-top: 30px !important;
}
.pd-t35 {
  padding-top: 35px !important;
}
.pd-t40 {
  padding-top: 40px !important;
}
.pd-t45 {
  padding-top: 45px !important;
}
.pd-t50 {
  padding-top: 50px !important;
}
.pd-t60 {
  padding-top: 60px !important;
}
.pd-t70 {
  padding-top: 70px !important;
}
.pd-t80 {
  padding-top: 80px !important;
}
.pd-t100 {
  padding-top: 100px !important;
}
.pd-t125 {
  padding-top: 125px !important;
}
.pd-t150 {
  padding-top: 150px !important;
}

.pd-l0 {
  padding-left: 0 !important;
}
.pd-l5 {
  padding-left: 5px !important;
}
.pd-l10 {
  padding-left: 10px !important;
}
.pd-l15 {
  padding-left: 15px !important;
}
.pd-l20 {
  padding-left: 20px !important;
}
.pd-l25 {
  padding-left: 25px !important;
}
.pd-l30 {
  padding-left: 30px !important;
}
.pd-l35 {
  padding-left: 35px !important;
}
.pd-l40 {
  padding-left: 40px !important;
}
.pd-l45 {
  padding-left: 45px !important;
}
.pd-l50 {
  padding-left: 50px !important;
}

.pd-r0 {
  padding-right: 0 !important;
}
.pd-r5 {
  padding-right: 5px !important;
}
.pd-r10 {
  padding-right: 10px !important;
}
.pd-r15 {
  padding-right: 15px !important;
}
.pd-r20 {
  padding-right: 20px !important;
}
.pd-r25 {
  padding-right: 25px !important;
}
.pd-r30 {
  padding-right: 30px !important;
}
.pd-r35 {
  padding-right: 35px !important;
}
.pd-r40 {
  padding-right: 40px !important;
}
.pd-r45 {
  padding-right: 45px !important;
}
.pd-r50 {
  padding-right: 50px !important;
}

.pd-b0 {
  padding-bottom: 0 !important;
}
.pd-b5 {
  padding-bottom: 5px !important;
}
.pd-b10 {
  padding-bottom: 10px !important;
}
.pd-b15 {
  padding-bottom: 15px !important;
}
.pd-b20 {
  padding-bottom: 20px !important;
}
.pd-b25 {
  padding-bottom: 25px !important;
}
.pd-b30 {
  padding-bottom: 30px !important;
}
.pd-b35 {
  padding-bottom: 35px !important;
}
.pd-b40 {
  padding-bottom: 40px !important;
}
.pd-b45 {
  padding-bottom: 45px !important;
}
.pd-b50 {
  padding-bottom: 50px !important;
}
.pd-b60 {
  padding-bottom: 60px !important;
}
.pd-b70 {
  padding-bottom: 60px !important;
}
.pd-b80 {
  padding-bottom: 80px !important;
}
.pd-b100 {
  padding-bottom: 100px !important;
}

.font-8 {
  font-size: 8px !important;
}
.font-10 {
  font-size: 10px !important;
}
.font-11 {
  font-size: 11px !important;
}
.font-12 {
  font-size: 12px !important;
}
.font-13 {
  font-size: 13px !important;
}
.font-14,
.fs14 {
  font-size: 14px !important;
}
.font-15 {
  font-size: 15px !important;
}
.font-16 {
  font-size: 16px !important;
}
.font-18 {
  font-size: 18px !important;
}
.font-19 {
  font-size: 19px !important;
}
.font-20 {
  font-size: 20px !important;
}
.font-24 {
  font-size: 24px !important;
}
.font-26 {
  font-size: 26px !important;
}
.font-28 {
  font-size: 28px !important;
}
.font-30 {
  font-size: 30px !important;
}
.font-32 {
  font-size: 32px !important;
}
.font-35 {
  font-size: 35px !important;
  letter-spacing: -1px;
}
.font-38 {
  font-size: 38px !important;
  letter-spacing: -2px;
}
.font-40 {
  font-size: 40px !important;
  letter-spacing: -2px;
}
.font-45 {
  font-size: 45px !important;
  letter-spacing: -2px;
}
.font-50 {
  font-size: 50px !important;
  letter-spacing: -2px;
}
.font-60 {
  font-size: 60px !important;
  letter-spacing: -3px;
}
.font-70 {
  font-size: 80px !important;
  letter-spacing: -3px;
}
.font-80 {
  font-size: 80px !important;
  letter-spacing: -3px;
}

/*----------- width, height -----------*/
.w-10 {
  width: 10%;
}
.w-20 {
  width: 20%;
}
.w-30 {
  width: 30%;
}
.w-40 {
  width: 40%;
}
.w-50 {
  width: 50%;
}
.w-60 {
  width: 60%;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-90 {
  width: 90%;
}

.w-40 {
  width: 35%;
}
.w-60 {
  width: 65%;
}

/*----------- common style 관련 -----------*/
.bg-orange {
  background-color: #FF9F43;;
}
.bg-gray {
  background-color: #F4F6F9;
}

.common-border-radius-light {
  border-radius: .2rem;
}

.sub-title {
  font-size: 26px;
  font-weight: 500;
}

.cursor-pointer {
  cursor: pointer;
}

/*----------- card -----------*/


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin: 0 0 25px;
  border: 1px solid #E8EBED;
  border-radius: 6px;
}


.card-body {
  padding: 20px;
  flex: 1 1 auto;
  padding: 1rem 1rem;
}
/* 
.card-header{
  border-bottom:0;
  background: $__transparent;
  @include margin-padding(null, 20px);
  .dropset {
      color: $__gray__dark;
      @include margin-padding(null, 0 10px);
  }
} */

/* .card-title{
  font-size: $__font__size__18;
  font-weight: $__bold;
  color: $__secondarycolor;
  @include margin-padding(0 0 20px, null);
  @include respond-below(custom991) {
      font-size: $__font__size__14;
  }
} */



/*----------- 버튼 -----------*/


.excel_btn {
  background-color: #004524;

}

.common_border_btn {
  font-size: 0.85em;
  border: 2px solid #87bd2b;
  color: #87bd2b;
  background-color: #fff;
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  height: 30px;
}

.dropdown-menu {
  box-shadow: #0000001a 0 6px 24px;
  min-width: 120px;
  overflow-y: scroll;
  --bs-dropdown-zindex: 50;
}
.dropdown-item {
  --bs-dropdown-link-active-bg: #131b4a;
}
.dropdown-menu::-webkit-scrollbar {
  width: 12px;
}
.dropdown-menu::-webkit-scrollbar-thumb {
  background-color: #aaa;
  border-radius: 5rem;
  background-clip: padding-box;
  border: 2px solid transparent;
}
.dropdown-menu::-webkit-scrollbar-track {
  border-radius: 5rem;
  background-color: #f1f1f1;
  box-shadow: inset 0px 0px 5px white;
}

/* Grid.jsx Scroll bar custom */
.MuiDataGrid-virtualScroller {
  overflow: hidden;
  overflow-y: scroll;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 10px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-track {
  background: #e3e3e3;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 45px;
}
.MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover {
  background: #757575;
}

@media (max-width: 990px) {
  .common-pd-left-right {
    padding-left: 1.4rem;
    padding-right: 1.4rem;
  }

  .sidebar_open header,
  .sidebar_closed header {
    margin-left: 0px;
    width: 100%;
  }

  .sm-mg-t20 {
    margin-top: 20px;
  }
}
